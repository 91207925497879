import { Box } from "@mui/material";
import Navbar from "scenes/navbar";
import TopBox from "scenes/topBox/TopBox";
import "./dashboard.css";
import ChartBox from "scenes/chartBox/ChartBox";
import PieChartBox from "scenes/pieChart/PieChart";
import BigChartBox from "scenes/bigChartBox/BigChartBox";
import ChartPubliBox from "scenes/chartBox/CharPubliBox";
// import WidgetIzquierda from "scenes/reports/WidgetIzquierda";
// import WidgetPrimero from "scenes/reports/WidgetPrimero";


const Dashboard = () => {
  // const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    return (
      <Box>
        <Navbar />
        <div className="home">
            <div className="box box1">
                <TopBox />
            </div>
            <div className="box box2"><ChartBox/></div>
            <div className="box box3"><ChartPubliBox/></div>
            <div className="box box4"><PieChartBox/></div>
            <div className="box box5"><BigChartBox/></div>

        </div>
      </Box>
    )
}
export default Dashboard;