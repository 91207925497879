import { useEffect, useState } from "react";
import "./chartBox.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Line, LineChart, ResponsiveContainer } from "recharts";

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const ChartPubliBox =() => {
    const token = useSelector((state) => state.token);
  const [totalPost, setTotalPost] = useState(null);

  const getTotalPost = async () => {
    try {
      // const response = await fetch("http://localhost:3001/posts", {
        const response = await fetch("https://proyectbackpg.onrender.com/posts", {
        // https://proyectbackpg.onrender.com/
        // Cambia la URL a la ruta correcta para obtener el total de usuarios
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        const data = await response.json();
        const count = data.length;
        setTotalPost(count);

      } else {
        console.error("Error al obtener el total de usuarios");
      }
    } catch (error) {
      console.error("Error al obtener el total de usuarios", error);
    }
  };

  useEffect(() => {
    getTotalPost();
  });


    return (
        <div className="chartBox">
            <div className="boxInfo">
              <div className="title">
                <h5>Total Reportes</h5> 
              </div>
              <h1>{totalPost}</h1>
              <Link to="/">Ver Mas</Link>
            </div>
            <div className="chartInfo">
              <div className="chart">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={300} height={100} data={data}> 
                  <Line type="monotone" dataKey="pv" stroke="#8884d8" strokeWidth={2} dot={false}/>
                </LineChart>
              </ResponsiveContainer>
              </div>
              <div className="texts">
                <span style={{ color: "#00cff3" }} className="percentage">Publicaciones</span>
                <span style={{ color: "limegreen" }} className="duration">Realizados</span>
              </div>
            </div>
        </div>
    )
}

export default ChartPubliBox;