import { Box } from "@mui/material";
import Navbar from "scenes/navbar";
import { useNavigate } from "react-router-dom";
import './inicio.css';

const HomePage = () => {
  const navigate = useNavigate();

    return (
      <Box>
        <Navbar />
        <div className="intro">
          <div className="i-left">
            <div className="i-name">
              <span>Bienvenidos al</span>
              <span>Municipio de Cabricán</span>
              <span>
                Pagina Web de seguridad ciudadana dirigida especificamente para proporcionar informacion y recursos relacionados con la areguridad de este Municipio
                <br />
                <br />
                Esta plataforma se utiliza para mantener a los ciudadano del  municipio infromados sobre temas de seguridad locales, promover la prevencion
                de delitos y ofrecer recursos utiles, como numeros de emergencias en el apartado de denuncias y consejos para mantenerse seguros en el entorno 
                municipal
                </span>
            </div>

            <button className="button i-button"  onClick={() => navigate("/home/create")}>
              Alerta
            </button>

          </div>
          
          <div className="i-rigth">
            <img src="https://drive.google.com/uc?export=download&id=1DNk1g4Xr6MLQ2ejf1igupHDzKt2vfofB" alt="" />
            <img src="https://drive.google.com/uc?export=download&id=1qHb7iVHRySQY7-U6xhArAWp2LXGmIZXe" alt="" />
            {/* <img src="https://drive.google.com/uc?export=download&id=1Fc1yycwaUFhZDRuI45Wt6cPKmp7BJf9Z" alt="" /> */}
            <img src="https://drive.google.com/uc?export=download&id=1PQcxwAnIFoEGzcMysB9tw4BE83fHyjI3" alt="" />
            {/* https://drive.google.com/file/d/1PQcxwAnIFoEGzcMysB9tw4BE83fHyjI3/view?usp=sharing */}

            <img src="https://drive.google.com/uc?export=download&id=1iEFHMSeXdAYsw7NHVnZh0qBWwKHbWyT8" alt="" />


            <div style={{top: '-8%', left: '68%'}}>
              <div className="floatingdiv">
                <img src="https://drive.google.com/uc?export=download&id=17r-0MloYMOtXudiIs5HB731m3yNPjkzB" alt="" />
                  <span>
                    Reporte
                    <br/>
                    Ayuda
                  </span>
              </div>
            </div>

            <div className="nose" style={{top: '25rem', left: '0rem'}}>
              <div className="floatingdiv" >
                <img src="https://drive.google.com/uc?export=download&id=1LgGmHMVTd9tdWE7QTZxTfvmpOZW5bme6" alt="" />
                  <span>
                    Unidos
                    <br/>
                    Siempre
                  </span>
              </div>
            </div>

            {/* blur divs */}
            <div className="blur" style={{background: "rgb(238 210 255)"}}>

            </div>

            <div className="blur" style={{
              // background: "rgb(238 210 255)",
              background: '#C1F5FF',
              top: '19rem',
              width: '21rem',
              height: '11rem',
              left: '-9rem'
            }}></div>
          </div>
        </div>
      </Box>
    )
}
export default HomePage;