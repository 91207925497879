import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import "./pieChart.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";


  
  const PieChartBox = () => {

    const token = useSelector((state) => state.token);
    const [totalPost, setTotalPost] = useState(null);
    const [totalUsers, setTotalUsers] = useState(null);
    const [totalCommun, setTotalCommun] = useState(null);

    const getTotalUsers = async () => {
        // const response = await fetch("http://localhost:3001/users", {
          const response = await fetch("https://proyectbackpg.onrender.com/users", {
          // https://proyectbackpg.onrender.com/
          // Cambia la URL a la ruta correcta para obtener el total de usuarios
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });
          const data = await response.json();
          const count = data.length;
          setTotalUsers(count);
  
    };

    const getTotalCommun = async () => {
      const response = await fetch("https://proyectbackpg.onrender.com/community", {
        // Cambia la URL a la ruta correcta para obtener el total de usuarios
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
        const data = await response.json();
        const count = data.length;
        setTotalCommun(count);

  };
  
    const getTotalPost = async () => {
        const response = await fetch("https://proyectbackpg.onrender.com/posts", {
          // Cambia la URL a la ruta correcta para obtener el total de usuarios
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });
  
          const data = await response.json();
          const count = data.length;
          setTotalPost(count);
      
    };

    useEffect(() => {
      getTotalUsers();
    });

    useEffect(() => {
      getTotalCommun();
    });
  
    useEffect(() => {
      getTotalPost();
    });

  const data = [
    { name: "PUblicaciones", value: totalPost, color: "#0088FE" },
    { name: "Usuarios", value: totalUsers, color: "#00C49F" },
    { name: "Communidad", value: totalCommun, color: "#FFBB28" },
  ];


    return (
      <div className="pieChartBox">
        <h1>Leads by Source</h1>
        <div className="chart">
          <ResponsiveContainer width="99%" height={300}>
            <PieChart>
              <Tooltip
                contentStyle={{ background: "white", borderRadius: "5px" }}
              />
              <Pie
                data={data}
                innerRadius={"70%"}
                outerRadius={"90%"}
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((item) => (
                  <Cell key={item.name} fill={item.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="options">
          {data.map((item) => (
            <div className="option" key={item.name}>
              <div className="title">
                <div className="dot" style={{ backgroundColor: item.color }} />
                <span>{item.name}</span>
              </div>
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default PieChartBox;