import { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import "./topBox.css";


const TopBox = () => {
    const token = useSelector((state) => state.token);
    const [users, setUsers] = useState(null);

    const getUsers = async () => {
        // try {
        //   const response = await fetch("http://localhost:3001/users", {
        const response = await fetch("https://proyectbackpg.onrender.com/users", {
            // https://proyectbackpg.onrender.com/
            // Cambia la URL a la ruta correcta para obtener todos los usuarios
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          });
    
    
        const data = await response.json();
        setUsers(data);
         
      };
    
      useEffect(() => {
        getUsers();
      }); 
    
    
 
      if (!users) return null;

  // const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    return (
        <div className="topBox">
            <h1>Listado de Usuarios</h1>
            <div className="list"> 
                {users.map(user => (
                    <div className="listItem" key={user.id}>
                        <div className="user">
                            {/* <img className="imagen" src={ `http://localhost:3001/assets/${user.picturePath}`} alt="" /> */}
                            <img className="imagen" src={ `https://proyectbackpg.onrender.com/assets/${user.picturePath}`} alt="" />
                            {/* https://proyectbackpg.onrender.com/ */}
                            <div className="userTexts">
                                <span className="username">{user.firstName}</span>
                                <span className="email">{user.email}</span>
                            </div>
                        </div>
                        <span className="amount">{user.location}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default TopBox;