import {
    ChatBubbleOutlineOutlined,
    FavoriteBorderOutlined,
    FavoriteOutlined,
    DeleteOutlineOutlined,
  } from "@mui/icons-material";
  import { Box, Button, Divider, IconButton, TextField, Typography, useTheme } from "@mui/material";
  import FlexBetween from "components/FlexBetween";
  import Friend from "components/Friend";
  import WidgetWrapper from "components/WidgetWrapper";
  import { useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { setPost } from "state";
  import { useNavigate } from "react-router-dom";
  import parse from "html-react-parser";
  import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  

  const PostWidgetCommunity = ({
    postId,
    postUserId,
    name,
    description,
    location,
    userPicturePath,
    likes,
    comments,
  }) => {
    const [isComments, setIsComments] = useState(false);
    // const [isComments, setIsComments] = useState(false);
    const [commentText, setCommentText] = useState(''); // Estado para almacenar el texto del comentario
    const dispatch = useDispatch();
    const token = useSelector((state) => state.token);
    const loggedInUserId = useSelector((state) => state.user._id);
    const isLiked = Boolean(likes[loggedInUserId]);
    const likeCount = Object.keys(likes).length;
    const navigate = useNavigate();
  
    const { palette } = useTheme();
    const main = palette.neutral.main;
    const primary = palette.primary.main;

  
    const patchLike = async () => {
      const response = await fetch(`https://proyectbackpg.onrender.com/community/${postId}/like`, {
       
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId }),
      });
      const updatedPost = await response.json();
      dispatch(setPost({ post: updatedPost }));
    };

    const handleDeletePost = async () => {
      try {
        const response = await fetch(`https://proyectbackpg.onrender.com/community/${postId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        if (response.status === 200) {
          // Eliminación exitosa, muestra una notificación y actualiza la lista de publicaciones.
          toast.error("¡Publicación eliminada!", {
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },

          });

        }
      } catch (error) {
        console.error("Error al eliminar el post:", error);
      }
    };

    // Función para enviar un comentario
  const handleCommentSubmit = async () => {
    if (commentText.trim() === '') {
      // Evitar comentarios vacíos
      return;
    }

    const response = await fetch(`https://proyectbackpg.onrender.com/community/${postId}/comment`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: commentText }),
    });

    if (response.status === 201) {
      // Comentario creado exitosamente, actualiza la vista
      const updatedPost = await response.json();
      dispatch(setPost({ post: updatedPost }));
      setCommentText(''); // Limpia el área de comentario después de enviar
    } else {
      // Manejar errores o mostrar un mensaje al usuario si la creación del comentario falla.
      // Puedes mostrar una notificación de error aquí si lo deseas.
    }
  };

     // Función para detectar y mostrar la descripción como enlace o video incrustado
     const renderDescription = () => {
      if (!description) {
        return null;
      }

   
      const isYouTubeVideo = (url) => {
        // Agrega aquí la lógica para detectar URLs de video de YouTube.
        return url.includes("youtube.com"); // Esto es un ejemplo básico.
      };

      // Si la descripción es una URL de video de YouTube, muestra el video incrustado.
      if (isYouTubeVideo(description)) {
        const videoUrl = description;
        const videoId = videoUrl.split("v=")[1]; // Extrae el ID del video de la URL de YouTube.
        
        const containerStyle = {
          position: 'relative',
          width: '100%',
          paddingBottom: '56.25%', // Esta proporción mantiene el video en 16:9.
        };

        const iframeStyle = {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        };

        return (
          <div style={containerStyle}>
          <iframe
            // width="100%"
            // height="auto"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameborder="0"
            allowfullscreen
            style={iframeStyle}
          ></iframe>
          </div>
        );
      }

      // Si no es un video de YouTube, muestra la descripción como un enlace.
      return parse(`<p>${description}</p>`); // Utiliza parse para convertir la descripción en HTML.
    };

  
    return (
      <WidgetWrapper m="2rem 0">
        <Friend
          friendId={postUserId}
          name={name}
          subtitle={location}
          userPicturePath={userPicturePath}
        />
        {renderDescription()}
        <Typography color={main} sx={{ mt: "1rem" }}>
          {description}
        </Typography>
      
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            <FlexBetween gap="0.3rem">
              <IconButton onClick={patchLike}>
                {isLiked ? (
                  <FavoriteOutlined sx={{ color: primary }} />
                ) : (
                  <FavoriteBorderOutlined />
                )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>
  
            <FlexBetween gap="0.3rem">
              <IconButton onClick={() => setIsComments(!isComments)}>
                <ChatBubbleOutlineOutlined />
              </IconButton>
              <Typography>{comments.length}</Typography>
            </FlexBetween>
          </FlexBetween>
  
          {/* <IconButton>
            <ShareOutlined />
          </IconButton> */}

          <IconButton onClick={ handleDeletePost } >
            <DeleteOutlineOutlined onClick={() => navigate('/home')}/> 
          </IconButton>
        {/* )} */}
        

          {/* <IconButton
            onClick={() => navigate(`/maps/${postId}`)}
          >
            <AddLocation />
          </IconButton> */}
        </FlexBetween>
        {/* {isComments && (
          <Box mt="0.5rem">
            {comments.map((comment, i) => (
              <Box key={`${name}-${i}`}>
                <Divider />
                <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
                  {comment}
                </Typography>
              </Box>
            ))}
            <Divider />
          </Box>
        )} */}
   

   {isComments && (
  <Box mt="0.5rem">
    {/* Muestra los comentarios existentes */}
    {/* {comments.map((comment, i) => (
      <Box key={`${name}-${i}`}>
        <Divider />
        <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
          {comment.text} {/* Accede a la propiedad 'text' del objeto de comentario */}
        {/* </Typography>
      </Box>
    ))} */} 
    {comments.map((comment, i) => (
        <Box key={`comment-${i}`} sx={{ ml: `${comment.depth * 1}rem` }}>
          <Divider />
          <Typography sx={{ color: 'primary', m: '0.5rem 0' }}>
            {comment.text}
          </Typography>
        </Box>
      ))}
    <Divider />
    
    {/* Área para ingresar un nuevo comentario */}
    <TextField
      label="Agregar comentario"
      variant="outlined"
      fullWidth
      value={commentText}
      onChange={(e) => setCommentText(e.target.value)}
      sx={{ mt: '0.5rem' }}
    />
    <Button
      variant="contained"
      color="primary"
      onClick={handleCommentSubmit}
      sx={{ mt: '0.5rem' }}
    >
      Comentar
    </Button>
  </Box>
)}


{/* {isComments && (
        <Box mt="0.5rem">
          {comments.map((comment, i) => (
            <Box key={`${name}-${i}`}>
              <Divider />
              <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
                {comment.text}
              </Typography>
            </Box>
          ))}
          <Divider />
          
          <TextField
            label="Agregar comentario"
            variant="outlined"
            fullWidth
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            sx={{ mt: '0.5rem' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCommentSubmit}
            sx={{ mt: '0.5rem' }}
          >
            Comentar
          </Button>
        </Box>
      )} */}
      </WidgetWrapper>
    );
  };
  
  export default PostWidgetCommunity;