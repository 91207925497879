import { Box, useMediaQuery } from "@mui/material";
import Navbar from "scenes/navbar";
import { useSelector } from "react-redux";

import MyPostCommunity from "scenes/widgets/MyPostCommunity";
import PostsWidgetCommunity from "scenes/widgets/PostsWidgetCommunity";
// import { Toaster, toast } from "sonner";
// import { BiCheck } from 'react-icons/bi';
// import { IconButton } from '@mui/material';

const Community = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) => state.user);
    // const clickNotifu = () => {
    //   if (Notification.permission !== "granted") {
    //     Notification.requestPermission().then((permission) => {
    //       if (permission === "granted") {
    //         showNotification();
    //       }
    //     });
    //   } else {
    //     showNotification();
    //   }
    // };
    
    // const showNotification = () => {
    //   const notification = new Notification('Prueba de la notificación', {
    //     body: 'Ver',
    //   });
    
    //   notification.onclick = () => {
    //     window.open('https://www.youtube.com/', '_blank'); // Abre una nueva pestaña con la URL deseada
    //     notification.close();
    //   };
    // };
    return (
      <Box>
        <Navbar />
        <Box
          width="100%"
          padding="2rem 6%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
        <Box flexBasis={isNonMobileScreens ? "6%" : undefined}>
            {/* <UserWidget userId={_id} picturePath={picturePath} /> */}
        </Box>

        
        <Box
            flexBasis={isNonMobileScreens ? "50%" : undefined}
            mt={isNonMobileScreens ? undefined : "2rem"}
          >
        <h1 className='head-text'>Postear Algo</h1>
            <MyPostCommunity picturePath={picturePath} /> 
            <PostsWidgetCommunity userId={_id} />
          </Box>

          <Box flexBasis={isNonMobileScreens ? "6%" : undefined}>
            {/* <UserWidget userId={_id} picturePath={picturePath} /> */}
        </Box>

          </Box>
        {/* Bienvenidos Comunidad
        <button onClick={clickNotifu} style={{margin: '100'}}>
          Realizar prueba
        </button>
        <br />
        <br />

        <button onClick={() => {
          toast('Terminos Aceptados',{
            description: "Greciassss",
            icon: <IconButton />
          })
        }}>
          Pruebass 2
        </button>

        <Toaster/> */}
      </Box>
    )
}
export default Community;