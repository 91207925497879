import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={theme.palette.background.alt}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography fontWeight="bold" fontSize="32px" color="primary">
          <img 
           src="https://drive.google.com/uc?export=download&id=1gHFV8mVfomxEDFV0PnvcNWqGhTXgdBDy"
          style={{ marginRight: "20px", width: "50px", height: "50px" }} alt="" />
          Municipio de Cabricán
        </Typography>
      </Box>

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
      >
        <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
          Bienvenido a la Red Cabricán. ¡Aquí puedes estar Informado!
        </Typography>
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;