import { Box } from "@mui/material";
import Navbar from "scenes/navbar";
import "./report.css";
import Card from "./Card";
import Iframe from "react-iframe";


const Report = () => {
    return (
      <Box>
        <Navbar />
        <div className="intro">
          <div className="services">
              {/* left side */}
              <div className="awesome">
                  <span>Realiza una Denuncia</span>
                  <span>¿Dónde puedo realizar?</span>
                  <button className="button s-button"> <a href="https://www.mp.gob.gt/mapa-de-cobertura/" target="_blank" rel="noopener noreferrer"> Ver centros Disponibles </a></button>
                  <spane>
                      <h4>Si fuiste víctima o testigo de un delito, puedes presentar una denuncia de 
                      <br />
                      manera segura y confidencial ante las siguientes instituciones:
                      </h4>
                      <h2>Ministerio Publico </h2>
                      <ul>
                          <li>Llama a 1574 al centro a llamadas del MP 24 horas 365 días al año.</li>
                          <li>Ingresa al MP Virtual</li>
                          <li>Visita una fiscalía del MP. Busca la ubicación más cerca aquí.</li>
                          <li>Escribe a 5708-7354, si eres una persona con discapacidad auditiva.</li>
                      </ul>
                      <h2>Policía Nacional Civil</h2>
                      <ul>
                          <li>Llama a 1518 para hacer una denuncia confidencial.</li>
                          <li>Ingresa al sitio de Denuncia Confidenciales.</li>
                          <li>Visita la Oficina de Atención a la Víctima en una comisaría o estación local.</li>
                      </ul>
                      <h2>Organismo Judicial</h2>
                      <ul>
                          <li>Visita un Juzgado de Paz, de Instancia</li>
                          <li>o Juzgados especializados por delito.</li>
                      </ul>
                  </spane>
                  <div className="blur s-blur" 
                  style={{ 
                    background: "#ABF1FF94",
                    top: '19rem',
              width: '21rem',
              height: '11rem',
              left: '-9rem'
                    }}></div>
              </div>

              {/* rigt side */}
              <div className="cards">
                  
                  <div style={{ top: "-2rem", left: "29rem" }}>
                      <Card
                          emoji={'https://drive.google.com/uc?export=download&id=1dzk3HgKrcJifuDtJhdtQ4EVbn4_5v2KI'}
                          heading={'Hacer una Denuncia MP'}
                          detail={"Haz tu denuncia a travez del MP Virtual, en el siguiente Link"}
                          enlace={'https://www.mp.gob.gt/denuncias/'}
                          nEnlace={"Yo Denuncio"}
                      />
                  </div>

                  <div className="video" style={{ top: "-3rem", left: "3rem" }}>
                  <Iframe
                        width="100%"
                        height="auto"
                        src="https://www.youtube.com/embed/svV1mxxEz3I"
                        frameborder="0"
                        allowfullscreen
                    />
                  </div>

                  <div style={{ top: "20rem", left: "3rem" }}>
                      <Card 
                          emoji={'https://drive.google.com/uc?export=download&id=1TAf4D2KafuAiSngPK80GgyTZ3zxO3ch5'}
                          heading={"Denuncia Confidencial"}
                          detail={"Haz una denuncia de manera anonima, con responsabilidad"}
                          enlace={'https://mingob.gob.gt/denuncias-confidenciales-1518/'}
                          nEnlace={"Ir a la Pagina"}
                      />
                  </div>

                  <div style={{ top: "17rem", left: "24rem"}}>
                      <Card 
                          emoji={'https://drive.google.com/uc?export=download&id=1RIoi_Czn5Sh26RC3so649pbKQVj6RMal'}
                          heading={"Consulta tu Denuncia"}
                          detail={
                          "Puedes consultar tu denuncia por medio de la pagina del MP a travez del siguiente LInk."
                          }
                          enlace={'https://www.mp.gob.gt/denuncias/'}
                          nEnlace={"Consultar Denuncia"}
                      />
                  </div>
                  
                  <div className="blur s-blur2" style={{ background: "rgb(238 210 255)" }}></div>

              </div>
          </div>
        </div>
      </Box>
    )
}
export default Report;