import React from "react";
import "./card.css"; 

const Card = ({emoji, heading, detail, enlace, nEnlace }) => {
    return (
        <div className="card">
            <img src={emoji} alt="" />
            <span> <h2>{heading}</h2>  </span>
            <span> {detail} </span>
            <button className="c-button"> <a href={enlace} target="_blank" rel="noopener noreferrer" > {nEnlace}  </a></button>
        </div>
    )
}

export default Card;