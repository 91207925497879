import { Box } from "@mui/material";
import Navbar from "scenes/navbar";
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGl, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from "react-map-gl";
import { useEffect, useRef, useState } from "react";
import carro from "./carro.png";
import mapboxgl from "mapbox-gl";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";



const TOKEN = 'pk.eyJ1IjoicHJveWVjdHNlbSIsImEiOiJjbG16Nm90b20xZjNzMnNxZnZndW84NjkzIn0.TNw0Y4xJbcm_gh3Use6RUQ'
// const TOKEN = 'pk.eyJ1IjoicHJveWVjdHNlbSIsImEiOiJjbG16NDJobmwxZW9tMm1xdjI3Yzk5dmJwIn0.8_XqiE0iWvCuo3eTnEVzmg'

const Maps = () => {
  const [post, setPost] = useState(null);
  const { postId } = useParams();
  const token = useSelector((state) => state.token);
  // const [user, setUser] = useState(null);

  const [viewPort, setViewport] = useState({
    latitude: 15.072507,
    longitude: -91.648700,
    zoom: 13
  })
  
  const [start, setStart] = useState([-91.648700, 15.072507]);
  const [end, setEnd] = useState([-91.648700, 15.09]);

  const getPost = async () => {
    const response = await fetch(`https://proyectbackpg.onrender.com/posts/${postId}`, {
      // const response = await fetch(`https://proyectbackpg.onrender.com/users/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setPost(data);

    setEnd([data.longitud, data.latitud]);

    console.log("User id", postId )
    console.log("Datos", data)
  };
  
  useEffect(() => {
    getPost();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



  
  const [coords, setCords] = useState([]);

  const [carPosition, setCarPosition] = useState({ longitude: start[0], latitude: start[1] });
  const [currentCoordinateIndex, setCurrentCoordinateIndex] = useState(0);

  const GeolocateControlRef = useRef();

  useEffect(()=>{
    getRoute();
    GeolocateControl.current?.trigger()
  }, [end, start, GeolocateControlRef]);
  
  
  const intervalTime = 3000; // Ajusta el intervalo para un movimiento más lento, por ejemplo, 3000 milisegundos (3 segundos)
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentCoordinateIndex < coords.length - 1) {
        const nextCoordinate = coords[currentCoordinateIndex + 1];
        setCarPosition({
          longitude: nextCoordinate[0],
          latitude: nextCoordinate[1],
        });
        setCurrentCoordinateIndex(currentCoordinateIndex + 1);
      } else {
        setCurrentCoordinateIndex(0);
      }
    }, intervalTime);
    
    return () => {
      clearInterval(interval);
    };
  }, [coords, currentCoordinateIndex, intervalTime]);
  
  
  const getRoute = async () => {
    const response = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${TOKEN}`);
    const data = await response.json();
    const coordinates  = data.routes[0].geometry.coordinates
    // console.log(coords)
    setCords(coordinates)
    
  }
  
  if (!post) return null;
  const geojson = {
    "type": "FeatureCollection",
    "features": [{
      "type": "feature",
      "geometry": {
        "type": "LineString",
        "coordinates": [
            ...coords
        ]
      }
    }]
  }

  const lineStyle = {
    id: 'roadLayer',
    type: 'line',
    layout:{
      "line-join": "round",
      "line-cap": "round"
    },
    paint: {
      "line-color": "blue",
      "line-width": 4,
      "line-opacity": 0.75
    }
  }
  const minPopup = new mapboxgl.Popup()
  // minPopup.setHTML('<h2>Ubicación</h2> <p>Tu Ubicacion</p>');
  minPopup.setHTML(`<h2>Tu Ubicacion</h2> Administrador:`);
  

  const minPopupUser = new mapboxgl.Popup()
  // minPopup.setHTML('<h2>Ubicación</h2> <p>Tu Ubicacion</p>');
  minPopupUser.setHTML(`<h2>Aletra de: ${post.firstName} ${post.lastName}</h2> <h4>Publicacion: ${post.description}</h4> <h4>Longitud: ${post.longitud}</h4> <h4>Latitud: ${post.latitud}</h4> `);

  return (
    <Box>
      <Navbar />
      <div style={{ width: "100vw", height: "85vh"}}>
        <ReactMapGl
          {...viewPort}
          mapboxAccessToken={TOKEN}
          // mapStyle='mapbox://styles/proyectsem/clmz7p0nq05xm01qi2xuu6tub'
          mapStyle='mapbox://styles/proyectsem/clmz7p0nq05xm01qi2xuu6tub'
          // mapStyle='mapbox://styles/mapbox/satellite-streets-v12'

       
          onMove={evt => setViewport(evt.viewState)}
        >

          <Source id="routeSource" type="geojson" data={geojson}>
            <Layer {...lineStyle} />
          </Source>

            <GeolocateControl onGeolocate={(e) => setStart([e.coords.longitude, e.coords.latitude])} ref={GeolocateControlRef}/>
            <FullscreenControl/>
            <NavigationControl/>
            

            <Marker longitude={start[0]} latitude={start[1]} popup={minPopup} />
            <Marker color="red" longitude={end[0]} latitude={end[1]} popup={minPopupUser} />

            <Marker longitude={carPosition.longitude} latitude={carPosition.latitude}>
              <img src={carro} alt="Carro" 
              style={{
                width: '60px', // Ancho deseado
                height: 'auto', // Alto deseado
              }}
              />
            </Marker>

          </ReactMapGl>
        </div>
    </Box>
  )
  // const [viewPort, setViewport] = useState({
  //   latitude: 15.072507,
  //   longitude: -91.648700,
  //   zoom: 13
  // })

  // const [start, setStart] = useState([-91.648700, 15.072507]);
  // const [end] = useState([-91.648700, 15.09]);



  //   return (
  //     <Box>
  //       <Navbar />
  //       <div style={{ width: "100vw", height: "85vh"}}>
  //           <ReactMapGl
  //             {...viewPort}
  //             mapboxAccessToken={TOKEN}
  //             mapStyle='mapbox://styles/proyectsem/clmz7p0nq05xm01qi2xuu6tub'
  //             // onViewportChange={viewPort => {
  //             //   setViewport(viewPort);
  //             // }}
  //             onMove={evt => setViewport(evt.viewState)}
  //           >
  //             <GeolocateControl/>
  //             <FullscreenControl/>
  //             <NavigationControl/>
  //             <Marker longitude={start[0] } latitude={start[1]}/>
  //             <Marker longitude={end[0]} latitude={[end[1]]} />
  //           </ReactMapGl>
  //         </div>
  //     </Box>
  //   );
}
export default Maps;